/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// require("typeface-noto-sans-tc")
// require("typeface-noto-sans-sc")
// require("typeface-lato")

import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const st = document.documentElement.scrollTop || document.body.scrollTop

  if (
    (location.state && location.state.preventScroll && st > 16) ||
    location.hash
  ) {
    return false
  }

  return true
}
