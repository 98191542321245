import React from "react"

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const RECAPTCHA_KEY = "6LcIUvgUAAAAAHtzBBlVqsEQF350F5DwmpTl5S6R"
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY} language={"en"}>
      {element}
    </GoogleReCaptchaProvider>
  )
}
