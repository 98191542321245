module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-162999323-3","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/@ivor/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"supportedLanguages":["zh-Hant","en","zh-Hans"],"defaultLanguage":"zh-Hant","siteUrl":"https://liyuenjing.com","deleteOriginalPages":true},
    },{
      plugin: require('../node_modules/@ivor/gatsby-theme-intl/gatsby-browser.js'),
      options: {"plugins":[],"supportedLanguages":["zh-Hant","en","zh-Hans"],"defaultLanguage":"zh-Hant","siteUrl":"https://liyuenjing.com","i18nextConfig":{"resources":{}},"deleteOriginalPages":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
